<template>
  <VForm
    v-slot="{isDisabled : { value: isDisabled }}"
    :disabled="formStatus === 'pending'"
    @submit.prevent="onSubmit"
  >
    <slot
      name="prepend"
      :email-verified="mainContact.email_verified"
      :phone-verified="mainContact.phone_verified"
    />

    <div id="contact1">
      <div
        v-if="_use.email.activated"
        class="form-group"
      >
        <VTextField
          v-model="mainContact.email"
          placeholder="john@doe.fr"
          label="Email"
          type="email"
          :disabled="isDisabled || (_use.disabledField && mainContact.email_disabled)"
          required
          :error-messages="v$.mainContact.email.$errors.map(e => e.$message as string)"
          @update:model-value="v$.mainContact.email.$touch"
          @input="checkEmail()(!v$.mainContact.email.$invalid)"
        />
      </div>

      <HEmailVerification
        v-if="(_use.email.verification && mainContact.show_email_verification && mainContact.email) || verificationForced"
        :embedded="true"
        :email="mainContact.email"
        @success="onVerificationSuccess('email', mainContact)"
        @modifyemail="onModify('email', mainContact)"
      />

      <template v-if="_use.names.activated">
        <div class="row marginBottom">
          <VTextField
            v-model="mainContact.first_name"
            placeholder="John"
            label="Prénom"
            :disabled="isDisabled || (_use.disabledField && (mainContact.phone_disabled || !mainContact.email_verified))"
            required
          />

          <VTextField
            v-model="mainContact.last_name"
            placeholder="Doe"
            label="Nom"
            :disabled="isDisabled || (_use.disabledField && (mainContact.phone_disabled || !mainContact.email_verified))"
            required
          />

          <div class="col-8" />
        </div>
      </template>

      <div
        v-if="_use.phone.activated"
      >
        <HPhoneNumberInput
          v-model="mainContact.phone"
          :disabled="isDisabled || (_use.disabledField && (mainContact.phone_disabled || !mainContact.email_verified))"
          :error-messages="v$.mainContact.phone.$errors.map(e => e.$message as string)"
          @valid="checkPhone()($event)"
        />
      </div>

      <HPhoneVerification
        v-if="
          _use.phone.activated &&
            _use.phone.verification &&
            mainContact.show_phone_verification
        "
        ref="phoneVerification"
        :country-code="mainContact.country_code"
        :phone="mainContact.phone"
        :contact-id="mainContact.id"
        :embedded="true"
        @check:success="onVerificationSuccess('phone', mainContact)"
        @modifynumber="onModify('phone', mainContact)"
      />

      <div
        v-if="_use.company.activated && !_use.second_contact.activated"
        class="form-check"
      >
        <VCheckbox
          v-model="mainContact.buying_for_company"
          label="Acheter pour une entreprise"
        />
      </div>

      <div
        v-if="_use.company.activated && mainContact.buying_for_company"
        id="company"
      >
        <h3>{{ $t("annonces.company") }}</h3>
        <br>
        <VTextField
          v-model="mainContact.company_name"
          placeholder="Nom de l'entreprise"
          required
        />
        <VTextField
          v-model="mainContact.registration_number"
          placeholder="SIREN"
          minlength="9"
          maxlength="9"
          type="number"
          required
          @wheel="$event.target.blur()"
        />

        <VTextField
          v-model="mainContact.registration_city"
          placeholder="Ville d'enregistrement"
          required
        />
      </div>

      <VCheckbox
        v-if="_use.cgu.activated"
        id="cgu"
        v-model="mainContact.cgu"
        density="compact"
        v-bind="{
          ...(_use.cgu.activated && {
            errorMessages: v$.mainContact.cgu.$errors.map(e => e.$message as string),
            required: true
          })
        }"
        @update:model-value="v$.mainContact.cgu?.$touch"
      >
        <template #label>
          <div class="ml-3">
            J'ai pris connaissance et accepte les
            <a
              target="_blank"
              href="/mentions-legales"
              @click.stop
            >
              conditions générales d'utilisation
            </a>
            .
          </div>
        </template>
      </VCheckbox>

      <VCheckbox
        v-if="_use.optin.activated"
        id="optin"
        v-model="mainContact.optin"
        density="compact"
        v-bind="{
          ...(_use.optin.activated && _use.optin.required && {
            errorMessages: v$.mainContact.optin.$errors.map(e => e.$message as string),
            required: true
          })
        }"
        label="J'accepte de recevoir les news homeloop"
        @update:model-value="v$.mainContact.optin?.$touch"
      />
    </div>

    <slot
      name="append"
      :email-verified="mainContact.email_verified"
      :phone-verified="mainContact.phone_verified"
      :main-contact="mainContact"
    />

    <div class="row">
      <div class="col-auto">
        <slot name="submitButton">
          <HButton
            id="confirm-contact-verification"
            type="submit"
            class="mt-4"
            :disabled="formStatus === 'pending' || (_use.email.verification && !mainContact.email_verified) || (_use.phone.verification && !mainContact.phone_verified)"
          >
            <slot name="submitButtonLabel">
              Confirmer
            </slot>
          </HButton>
        </slot>
      </div>
      <div
        v-if="formStatus === 'pending'"
        class="col-auto"
      >
        <VProgressCircular indeterminate />
      </div>
      <div class="col-auto">
        <VLayout>
          <VSnackbar
            v-model="errorMessage"
            color="red"
            pill
          >
            {{ errorMessage }}
          </VSnackbar>
          <VSnackbar
            v-model="validMessage"
            color="green"
            pill
          >
            {{ validMessage }}
          </VSnackbar>
        </VLayout>
      </div>
    </div>
  </VForm>
</template>

<script setup lang="ts">
import merge from 'lodash/merge'
import { useVuelidate } from '@vuelidate/core'
import { HRequired, HEmail } from '~/utils/validations'
import type { ContactModel } from '~/types'

const defaultUse = {
  email: {
    activated: true,
    verification: false
  },
  gender: {
    activated: true
  },
  phone: {
    activated: true,
    verification: true
  },
  names: {
    activated: true
  },
  cgu: {
    activated: true
  },
  optin: {
    activated: false,
    required: false
  },
  is_selling: {
    activated: true
  },
  company: {
    activated: false,
    toggle: false
  },
  second_contact: {
    activated: false,
    toggle: false,
    label: 'annonces.secondBuyer'
  },
  partner_optin: {
    activated: false,
    label: 'annonces.partner_optin'
  },
  agent_profession: {
    activated: false
  },
  disabledField: true
}

interface Props {
  use?: any,
  leadId?: number,
  acquisitionSource?: string
  hookSubmitEnd?: Function
}

const props = withDefaults(defineProps<Props>(), {
  use: undefined,
  leadId: undefined,
  acquisitionSource: 'vitrine',
  hookSubmitEnd: undefined
})
const emit = defineEmits(['submit:start', 'submit:end', 'submit:failed'])
// eslint-disable-next-line vue/require-prop-types
const formStatus = defineModel('formStatus', { default: 'idle' })

const { api } = useFeathers()
const { getCurrent } = useFunnel()
const config = useRuntimeConfig()

const _use = computed(() => {
  if (props.use) {
    return merge({
      ...defaultUse,
      phone: {
        activated: true,
        verification: false
      },
      email: {
        activated: true,
        verification: config.app.emailVerificationEnabled
      }
    }, props.use)
  }

  return {
    ...defaultUse,
    phone: {
      activated: true,
      verification: false
    },
    email: {
      activated: true,
      verification: config.app.emailVerificationEnabled
    }
  }
})

const verificationForced = ref(false)

const mainContact = reactive({
  id: null,
  country_code: '33',
  email: null,
  gender: null,
  first_name: null,
  last_name: null,
  phone: null,
  buying_for_company: null,
  company_name: null,
  registration_number: null,
  registration_city: null,
  optin: null,
  cgu: null,
  email_verified: false,
  phone_verified: false,
  email_disabled: false,
  phone_disabled: false,
  show_phone_verification: false,
  show_email_verification: false
})

const secondContact = reactive({
  id: null,
  country_code: '33',
  email: null,
  gender: null,
  first_name: null,
  last_name: null,
  phone: null,
  email_verified: true,
  phone_verified: true,
  email_disabled: false,
  phone_disabled: false,
  show_phone_verification: false,
  show_email_verification: false
})

const rules = {
  mainContact: {
    email: { ...HRequired, ...HEmail },
    ...(_use.value.phone.activated && { phone: { ...HRequired } }),
    ...(_use.value.optin.activated && _use.value.optin.required && {
      optin: { ...HBoolRequired }
    }),
    ...(_use.value.cgu.activated && {
      cgu: { ...HBoolRequired }
    }),
    ...(_use.value.optin.activated && { gender: { ...HRequired } })
  }
}

defineExpose({
  setMainContact
})

const errorMessage = ref()
const validMessage = ref()
const v$ = useVuelidate(rules, { mainContact }, { $scope: 'test' })

function checkPhone ({ useSecondContact } = { useSecondContact: false }) {
  return async function ({ isValid, countryData }: any) {
    if (!_use.value.phone.verification) {
      if (!useSecondContact) {
        mainContact.phone_verified = true
        return
      }

      secondContact.phone_verified = true
      return
    }

    if (!useSecondContact) {
      if (!isValid) {
        return
      }

      mainContact.country_code = countryData.dialCode

      if (!mainContact.show_email_verification) {
        mainContact.id = await saveContact(mainContact)
      }

      if (props.leadId) {
        const leads = getCurrent('leads')
        leads.contact_id = mainContact.id
        await leads.save()
      }

      mainContact.phone_disabled = true
      mainContact.email_disabled = true
      mainContact.show_phone_verification = true

      // return
    }
  }

  // this.countryCode = this.$refs.phoneInput2.countryData.dialCode
  // this.secondBuyer.contactId = await this.saveContact(this.secondBuyer)
  // this.phone2Disabled = true
  // this.email2Disabled = true
  // this.showPhone2Verification = true
}

function checkEmail ({ useSecondContact } = { useSecondContact: false }) {
  return async function (isValid: boolean) {
    const contact = useSecondContact ? secondContact : mainContact

    if (!contact.email) {
      return
    }

    if (!isValid) {
      contact.email_verified = false
      return
    }
    const existingContactResult = await getExistingContactByEmail(contact.email)
    const existingContact = existingContactResult[0]

    if (contact.email_verified) {
      contact.id = existingContact.id
      contact.email = existingContact.email
      contact.gender = existingContact.salutation ? existingContact.salutation : contact.gender
      contact.first_name = existingContact.first_name
      contact.last_name = existingContact.last_name
      contact.phone = existingContact.phone
    }

    if (!existingContactResult.length) {
      contact.show_email_verification = false
      contact.email_verified = true
      return
    }

    contact.show_email_verification = true
    contact.email_verified = false

    if (_use.value.email.verification || !existingContact.phone_verified) {
      contact.email_disabled = true
      contact.show_email_verification = true
      verificationForced.value = true

      return
    }

    contact.phone_disabled = true
    contact.show_email_verification = false
    // await checkPhone()
  }
}

function onVerificationSuccess (type: 'email' | 'phone', contact: ContactModel) {
  contact[`show_${type}_verification`] = false
  contact[`${type}_verified`] = true
  validMessage.value = `Votre ${type === 'phone' ? 'numéro' : 'e-mail'} à bien été vérifié`
  type === 'email' ? checkEmail()(true) : checkPhone()(true)
}

function onModify (type: 'email' | 'phone', contact: ContactModel) {
  contact[`show_${type}_verification`] = false
  contact[`${type}_disabled`] = false
}

async function getExistingContactByEmail (email: string) {
  const result = await api.service('contacts').find({ query: { email } })
  return result
}

async function saveContact (contactData: ContactModel) {
  const contact = api.service('contacts').new({
    ...contactData,
    ...(contactData.id && { id: contactData.id }),
    acquisition_source: props.acquisitionSource
  })

  const response = contactData.id ? await contact.patch() : await contact.save()

  return response.id
}

async function onSubmit () {
  try {
    if (formStatus.value === 'pending') {
      return
    }

    const isValid = await v$.value.$validate()
    console.log('isValid', isValid)
    if (!isValid) {
      errorMessage.value = 'Remplissez les champs requis'
      return
    }

    errorMessage.value = null
    formStatus.value = 'pending'

    await emit('submit:start')

    mainContact.id = await saveContact(mainContact)

    await props.hookSubmitEnd?.({ mainContact, secondContact })

    formStatus.value = 'success'

    await emit('submit:end', { mainContact, secondContact })
  } catch (error) {
    errorMessage.value = 'Une erreur est survenue'
    formStatus.value = 'error'
    console.error(error)
    await emit('submit:failed', error)
  }
}

function setMainContact (contact: any) {
  mainContact.id = contact.id
  mainContact.email = contact.email
  mainContact.gender = contact.salutation
  mainContact.first_name = contact.first_name
  mainContact.last_name = contact.last_name
  mainContact.phone = contact.phone
  mainContact.buying_for_company = null
  mainContact.company_name = contact.company_name
  mainContact.registration_number = contact.registration_number
  mainContact.registration_city = contact.registration_city
  mainContact.email_verified = true
  mainContact.phone_verified = true
}
</script>

<style scoped>

</style>
